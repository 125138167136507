<template>
  <div class="top-nav">
    <section class="home-section">
      <div class="home-content">
        <i class="bx bx-menu-alt-right" @click="toggleSidebar()"></i>
      </div>
    </section>
    <ul class="nav-list">
      <li class="nav-item active">
        <a class="nav-link" href="#">
          <i class="bx bxs-bell"></i>
        </a>
      </li>
      <li
        class="nav-item"
        @click="openSettings"
        v-click-outside="settingOutside"
      >
        <a class="nav-link">
          <i class="bx bxs-cog"></i>
        </a>
      </li>
      <li
        class="nav-item"
        @click="openUserMenu"
        v-click-outside="userMenuOutside"
      >
        <a class="nav-link">
          <i class="bx bxs-user-circle"></i>
        </a>
      </li>
    </ul>

    <ul v-if="userMenu" class="menu-options">
      <li class="nav-item">
        <a class="nav-link" @click="logout">
          <i class="fa fa-sign-out-alt"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>

    <div v-if="settings" class="menu-options">
      <ul>
        <li>
          <router-link to="/profile" class="nav-link">
            <i class="fa fa-user"></i>
            <span>Profile</span></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "Navbar",
  data() {
    return {
      userMenu: false,
      settings: false,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {},
  methods: {
    openUserMenu() {
      this.userMenu = !this.userMenu;
    },
    logout: function() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        color: "#ffee00",
        backgroundColor: "#333",
        opacity: 0.7,
      });

      axios
        .post(process.env.VUE_APP_API_URL + "auth/signout")
        .then((response) => {
          loader.hide();
          if (response.data.statusCode === 200) {
            localStorage.removeItem("authToken");
            this.$router.push({ name: "Login" });
          } else {
            alert(response.data.message);
          }
        });
    },
    userMenuOutside() {
      this.userMenu = false;
    },
    openSettings() {
      this.settings = !this.settings;
    },
    settingOutside() {
      this.settings = false;
    },

    toggleSidebar: function() {
      if (document.body.clientWidth > 400) {
        $(".sidebar").toggleClass("close");
        $(".main-wrapper").toggleClass("closeWrapper");
      } else {
        $(".sidebar").toggleClass("small-screen");
      }
    },
  },
};
</script>

<style></style>
