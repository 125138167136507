<template>
  <div class="sidebar">
    <div class="logo-details">
      <i class="bx bxs-store"></i>
      <span class="logo_name">JMS</span>
    </div>
    <ul class="nav-links">
      <li>
        <router-link to="/">
          <i class="bx bxs-dashboard"></i>
          <span class="link_name">Dashboard</span>
        </router-link>
        <ul class="sub-menu blank">
          <li>
            <router-link to="/">
              <span class="link_name">Dashboard</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <div class="icon-link dropdown-btn">
          <a>
            <i class="bx bxs-book-content"></i>
            <span class="link_name">Inventory</span>
          </a>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <ul class="sub-menu">
          <li>
            <router-link to="/categories">
              <i class="bx bxs-category"></i>
              Categories
            </router-link>
          </li>
          <li>
            <router-link to="/products">
              <i class="bx bxs-parking"></i>
              Products</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <div class="icon-link dropdown-btn">
          <a>
            <i class="bx bxs-user-pin"></i>
            <span class="link_name">People</span>
          </a>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <ul class="sub-menu">
          <li>
            <router-link to="/customers">
              <i class="bx bxs-user-check"></i>
              Customers
            </router-link>
          </li>
          <li>
            <router-link to="/manufacturers">
              <i class="bx bxs-user-account"></i>
              Manufacturers</router-link
            >
          </li>
          <li>
            <router-link to="/suppliers">
              <i class="bx bxs-user-detail"></i>
              Suppliers</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <div class="icon-link dropdown-btn">
          <a>
            <i class="bx bxl-paypal"></i>
            <span class="link_name">Payments</span>
          </a>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <ul class="sub-menu">
          <li>
            <router-link to="/payment-payable">
              <i class="bx bxs-wallet"></i>
              Payment Payable</router-link
            >
          </li>
          <li>
            <router-link to="/payment-receivable">
              <i class="bx bxs-wallet-alt"></i>
              Payment Receivable</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/Order">
          <i class="bx bxs-cart-download"></i>
          <span class="link_name">Orders</span>
        </router-link>
        <ul class="sub-menu blank">
          <li>
            <router-link to="/Order">
              <span class="link_name">Orders</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/balance-statment">
          <i class="bx bxs-cart-download"></i>
          <span class="link_name">Balance Statment</span>
        </router-link>
        <ul class="sub-menu blank">
          <li>
            <router-link to="/balance-statment">
              <span class="link_name">Balance Statment</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/configs">
          <i class="bx bxs-cog"></i>
          <span class="link_name">Configuration</span>
        </router-link>
        <ul class="sub-menu blank">
          <li>
            <router-link to="/configs">
              <span class="link_name">Configuration</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "LeftMenu",
  data: function() {
    return {};
  },
  methods: {},
  mounted() {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }
    let parent = document.querySelectorAll("a");
    for (var j = 0; j < parent.length; j++) {
      parent[j].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }

    let parentt = document.querySelectorAll(".dropdown-btn");
    for (var k = 0; k < parentt.length; k++) {
      parentt[k].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }

    $(function() {
      resizeScreen();
      $(window).resize(function() {
        resizeScreen();
      });

      function resizeScreen() {
        if (document.body.clientWidth < 400) {
          $(".sidebar").addClass("close");
        } else {
          $(".sidebar").removeClass("close");
        }
      }
    });
  },
};
</script>
